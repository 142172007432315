import { useState, useEffect, FC } from "react";
import { JobTitle, JobLogo, JobLocation, JobWrapper } from "./Job.styled";
import { IoLocationOutline } from "react-icons/io5";
import parse from "html-react-parser";

interface Props {
  job: {
    url: string[];
    LogoUrl: string[];
    title: string[];
    city: string[];
  };
}

const Job: FC<Props> = ({ job }) => {
  return (
    <JobWrapper href={job.url[0]} target="_blank" rel="noopener noreferrer">
      {job.LogoUrl ? (
        <JobLogo src={job.LogoUrl[0]} />
      ) : (
        <JobLogo src="/images/christin-hume.jpg" />
      )}
      <div>
        <JobTitle>{job.title && job.title[0]} </JobTitle>
        <JobLocation>
          <IoLocationOutline /> {job.city && job.city[0]}
        </JobLocation>
      </div>
    </JobWrapper>
  );
};

export default Job;
