import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";
import { IoArrowForwardCircleSharp } from "react-icons/io5";

export const Content = styled.div`
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

export const ActionWrapper = styled.div`
  max-width: 66%;
  margin-bottom: 30px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const StyledLink = styled.a`
  background: ${({ theme }) => theme.default.color.primary};
  color: ${({ theme }) => theme.default.color.backgrounds.light};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;
  border-radius: 24px;
  width: fit-content;
  padding: 8px 16px;
  justify-self: center;  
`;
