import { Wrapper } from "./TopBanner.styled";
import Ad from "Components/Ad";
import { FC } from "react";

interface Props {
  type?: string;
  className?: string;
  queryId?: string;
}

const TopBanner: FC<Props> = ({ type, className, queryId }) => {

  if (type === "Commercial") {
    return null;
  }
  return (
    <Wrapper className={className}>
      <Ad className="topbar" id={queryId ? `leaderboard${queryId}` : "leaderboard"}  width="100%" height="200" />
    </Wrapper>
  );
};

export default TopBanner;
