import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { setDefaultImage } from "common/Utils/defaults";
import { BreadcrumbJsonLd, NextSeo } from "next-seo";
import Head from "next/head";
import { FC, useContext } from "react";

type Props = {
  data: any;
};

const Meta: FC<Props> = ({ data }) => {
  const { publication, config } =
    useContext<publicationTypes>(PublicationContext);

  const seoTitle = data.data.section.extra?.titleSEO
    ? data.data.section.extra.titleSEO
    : `${config.default_Seo}`;
  const seoDescription = data.data.section.extra?.seoDescription
    ? data.data.section.extra.seoDescription
    : `${config.default_desc_seo}`;

  const logos: { [unit: string]: string } = {
    ["petersfieldpost.co.uk"]: "/images/logos/peters.png",
    ["farnhamherald.com"]: "/images/logos/FarnhamHerald.jpg",
    ["altonherald.com"]: "/images/logos/AltonHerald.png",
    ["bordonherald.com"]: "/images/logos/bordan_herald.png",
    ["haslemereherald.com"]: "/images/logos/haslemere_herald.png",
    ["liphookherald.com"]: "/images/logos/lipton.png",
    ["abergavennychronicle.com"]: "/images/logos/abergavennychronicle_2.jpg",
    ["monmouthshirebeacon.co.uk"]: "/images/logos/monmouthshirebeacon.jpg",
    ["chepstowbeacon.co.uk"]: "/images/logos/chepstowbeacon.jpg",
    ["brecon-radnor.co.uk"]: "/images/logos/brecon_radnor.jpg",
    ["cambrian-news.co.uk"]: "/images/logos/cambrian_news.jpg",
    ["tenby-today.co.uk"]: "/images/logos/tenby_today.jpg",
    ["narberth-and-whitland-today.co.uk"]:
      "/images/logos/narberth_and_whitland_today.jpg",
    ["pembroke-today.co.uk"]: "/images/logos/pembroke.jpg",
    ["theforester.co.uk"]: "/images/logos/theforester.jpg",
    ["rossgazette.com"]: "/images/logos/rossgazette.jpg",
    ["theforestreview.co.uk"]: "/images/logos/theforestreview_2.jpg",
    ["tavistock-today.co.uk"]: "/images/logos/TavistockTimes1.jpg",
    ["middevonadvertiser.co.uk"]: "/images/logos/MidDevonAdvertiser.jpg",
    ["dawlish-today.co.uk"]: "/images/logos/DawlishGazette.jpg",
    ["teignmouth-today.co.uk"]: "/images/logos/TeignmouthPost.jpg",
    ["dartmouth-today.co.uk"]: "/images/logos/DartmouthChronicle.jpg",
    ["ivybridge-today.co.uk"]: "/images/logos/Ivybridge&SouthBrentGazette.jpg",
    ["kingsbridge-today.co.uk"]: "/images/logos/kingsbridge-today.jpg",
    ["southhams-today.co.uk"]: "/images/logos/kingsbridge-today.jpg",
    ["totnes-today.co.uk"]: "/images/logos/TotnesTimes.jpg",
    ["tamarvalleytimes.co.uk"]: "/images/logos/tamarvalleytimes.jpg",
    ["okehampton-today.co.uk"]: "/images/logos/okehampton-today.jpg",
    ["creditoncourier.co.uk"]: "/images/logos/creditoncourier.jpg",
    ["iomtoday.co.im"]: "/images/logos/iom_logo.jpg",
    ["mnrjournal.co.uk"]: "/images/logos/mnr.jpg",
    ["wsfp.co.uk"]: "/images/logos/west_sumerset.jpg",
    ["wellington-today.co.uk"]: "/images/logos/wellington.jpg",
    ["holsworthy-today.co.uk"]: "/images/logos/holsworth.jpg",
    ["cornish-times.co.uk"]: "/images/logos/cornish_times.jpg",
    ["thepost.uk.com"]: "/images/logos/cornish.jpg",
    ["bude-today.co.uk"]: "/images/logos/Bude.jpg",
    ["wokingnewsandmail.co.uk"]: "/images/logos/Bude.jpg",
  };

  return (
    <>
      <NextSeo
        title={seoTitle}
        description={seoDescription.replace(/"/g, "")}
        openGraph={{
          title: seoTitle,
          description: seoDescription.replace(/"/g, ""),
          images: [
            {
              url: setDefaultImage(data?.data.section.name),
              alt: data?.data.section.name,
            },
          ],
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: data?.data.section.name,
            item: `www.${publication}${data?.data.section.path}`,
          },
        ]}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
          {
            "@type": "Organization",
            "@context": "https://schema.org",
            "name": "${config.pub}",
            "url": "https://www.${publication}",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.${publication}${logos[publication]}",
              "width": 150,
              "height": 50
            }
          }
              `,
        }}
      />
      <Head>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              (function() {
                /** CONFIGURATION START **/
                var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
                _sf_async_config.uid = 65613;
                _sf_async_config.domain = "${publication}"; //CHANGE THIS TO THE ROOT DOMAIN
                _sf_async_config.flickerControl = false;
                _sf_async_config.useCanonical = true;
                _sf_async_config.useCanonicalDomain = true;
                _sf_async_config.sections = "${data?.data.section.name}"; // CHANGE THIS TO YOUR SECTION NAME(s)
                _sf_async_config.authors = ""; // CHANGE THIS TO YOUR AUTHOR NAME(s)
                _sf_async_config.title = "${seoTitle}";
                _sf_async_config.pagetype = 'article';
                _sf_async_config.path = 'www.${publication}${data?.data.section.path}';
                /** CONFIGURATION END **/
                function loadChartbeat() {
                var e = document.createElement('script');
                var n = document.getElementsByTagName('script')[0];
                e.type = 'text/javascript';
                e.async = true;
                e.src = '//static.chartbeat.com/js/chartbeat.js';;
                n.parentNode.insertBefore(e, n);
                }
                loadChartbeat();
                })();
              `,
          }}
        />

        {/* Google Tag Manager */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${config.gtm_id}');
        `,
          }}
        />
        {/* End Google Tag Manager */}

        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', '${config.region}');
`,
          }}
        />

        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${config.region}`}
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `!function(a,l,b,c,k,s,t,g,A){a.CustomerConnectAnalytics=k,a[k]=a[k]||function(){
                (a[k].q=a[k].q||[]).push(arguments)},g=l.createElement(b),A=l.getElementsByTagName(b)[0],
                 g.type="text/javascript",g.async=!0,g.src=c+"?id="+s+"&parentId="+t,A.parentNode.insertBefore(g,A)
                }(window,document,"script","//carbon-cdn.ccgateway.net/script","cca",window.location.hostname,"3028f7536b");
            `,
          }}
        />
      </Head>
    </>
  );
};
export default Meta;
