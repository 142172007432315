import { FC, ReactNode } from "react";
import { Wrapper, InnerWrapper } from "./CenterWrapper.styled";

interface Props {
  children?: ReactNode;
  innerClassName?: string;
}

const CenterWrapper: FC<Props> = ({ children, innerClassName }) => {
  return (
    <Wrapper>
      <InnerWrapper className={innerClassName}>{children}</InnerWrapper>
    </Wrapper>
  );
};

export default CenterWrapper;
