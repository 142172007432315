import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";
import {IoIosArrowForward} from "react-icons/io";

export const SubscribeItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 0.5rem;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.default.color.backgrounds.offWhite};
  position: relative;
  border-radius: 16px;  
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
  }
  .content {
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;  
  }
  .tab {
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;
    text-transform: uppercase;
    color: #FFF;
    background: ${({ theme }) => theme.default.color.primary};
    padding: 4px;
    border-radius: 2px;
    margin-bottom: 4px;
    display: inline-block;
    width: fit-content;
  }
    
  .subscribe_left {
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;  
  }
`;

type StyledProps = {
  type: string;
};

export const RightSideImage = styled.div<StyledProps>`
  background-image: ${({ type }) => `url(${type})`};
  background-size: cover;
  background-position: center;
  border-radius: 0 16px 16px 0;
`;

export const ForwardArrow = styled(IoIosArrowForward)`
  font-size: 18px;
  margin-left: 5px;
  fill: ${({ theme }) => theme.default.color.primary};
`;

export const StyledLink = styled.a`
  display: flex;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  color: ${({ theme }) => theme.default.color.primary};  
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-items: center;  
  border: 1px solid ${({ theme }) => theme.default.color.primary};
  border-radius: 24px;
  padding: 4px 16px;
  width: 114px;
  justify-content: center;
  cursor: pointer;  
`;
