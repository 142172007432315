import { FC, useContext } from "react";
import dynamic from "next/dynamic";
import TopBanner from "../Adverts/TopBanner";
import CenterWrapper from "Components/CenterWrapper";
import Frame from "../Frame";
import Action from "./Actions/Action";
import { SectionProps } from "./types";
import Title from "Components/Title";
import FeaturedJobs from "Components/FeaturedJobs";
import { addTypes } from "common/context/Adverts/Adverts";
import { AdvertContext } from "common/context/Adverts";
import Meta from "./Meta";
import Skin from "Components/Skin";

const NewsLetterSignUp = dynamic(() => import("Components/NewsLetterSignUp"));
const EventSignUp = dynamic(() => import("Components/EventSignUp"));
const ArticleX3PlusMPU = dynamic(
  () => import("./ArticleX3PlusMPU/ArticleX3PlusMPU")
);
const HeroPlus1 = dynamic(() => import("./HeroPlus1"));
const HeroPlus2 = dynamic(() => import("./HeroPlus2"));
const HeroPlus3 = dynamic(() => import("./HeroPlus3"));
const HeroPlus6 = dynamic(() => import("./HeroPlus6"));
const MostRead = dynamic(() => import("./MostRead"));
const Topics = dynamic(() => import("./Topics"));
const Author = dynamic(() => import("./Author"));
const ArticleX6 = dynamic(() => import("./ArticleX6"));
const ArticleX6PlusMPU = dynamic(() => import("./ArticleX6PlusMPU"));
const MultiX2Articles = dynamic(() => import("./MultiX2Articles"));

const Section: FC<SectionProps> = ({ data, menus, jobs }) => {
  const { leaderboard } = useContext<addTypes>(AdvertContext);
  if (!data) {
    return null;
  }
  if (!data.data.components) {
    return null;
  }
  const components = data.data.components;
  const injectAdverts = () => {
    let newSectionBody: any = [];
    let count = 3;
    components.forEach((item: any, i: any) => {
      if (
        item.type === "ArticleX3PlusMPU" ||
        item.type === "ArticleX6PlusMPU" ||
        item.type === "MostPopular"
      ) {
        item.mpu = count;
        newSectionBody.push(item);
        count++;
      } else {
        newSectionBody.push(item);
      }
    });
    return newSectionBody;
  };
  let compCount = 0
  let isBannerPrinted = false;
  return (
    <Frame data={menus}>
      <Meta data={data} />
      <div style={{ marginBottom: 40 }}>
        <CenterWrapper innerClassName='section-wrapper'>
          <Skin id={`wp`} />
          {leaderboard && <TopBanner className='mobile_banner' queryId='_mob'/>}
          <Topics
            isTopic={data.data.type === "topic"}
            name={data.data.section.name}
          />
          <Author
            isAuthor={data.data.type === "author"}
            name={data.data.section?.name}
            image={data.data.section?.imageUrl}
          />
          {injectAdverts().map((data: any, i: number) => {
            const { type } = data;
            const isTitle = type === "Title";
            // Increment compCount only if the component is not a title
            if (!isTitle) {
              compCount++;
            }
            // Render the component first
            let componentToRender;
            switch (type) {
              case "Title":
                componentToRender = (
                  <Title key={i} type="section">
                    {data.labelText}
                  </Title>
                );
                break;
              case "HeroPlus1Articles":
                componentToRender = <HeroPlus1 key={i} data={data} />;
                break;
              case "HeroPlus2Articles":
                componentToRender = <HeroPlus2 key={i} data={data} />;
                break;
              case "HeroPlus3Articles":
                componentToRender = <HeroPlus3 key={i} data={data} />;
                break;
              case "HeroPlus6Articles":
                componentToRender = <HeroPlus6 key={i} data={data} />;
                break;
              case "Services":
                componentToRender = <Action key={i} type={data.service} />;
                break;
              case "ArticleX6PlusMPU":
                componentToRender = (
                  <ArticleX6PlusMPU mpu={true} mpu_id={data.mpu} key={i} data={data} />
                );
                break;
              case "ArticleX3PlusMPU":
                componentToRender = (
                  <ArticleX3PlusMPU mpu_id={data.mpu} key={i} data={data} />
                );
                break;
              case "ArticleX6":
                componentToRender = <ArticleX6 key={i} data={data} />;
                break;
              case "Multi2Articles":
                componentToRender = <MultiX2Articles key={i} data={data} />;
                break;
              case "NewsLetterSignup":
                componentToRender = <NewsLetterSignUp key={i} />;
                break;
              case "NewsLetterSignupEvents":
                componentToRender = <EventSignUp key={i} />;
                break;
              case "MostPopularPlusMPU":
                componentToRender = <MostRead data={data} key={i} mpu_id={data.mpu} />;
                break;
              default:
                componentToRender = null;
            }
            // Now check if we need to add the TopBanner after the 3rd component (without counting Titles)
            let additionalBanner = null;
            if (compCount === 3 && !isBannerPrinted && leaderboard) {
              isBannerPrinted = true;
              additionalBanner = <TopBanner className="desktop_banner" queryId='_dtop' />;
            }
            // Return the component and the banner (if applicable)
            return (
              <div key={i}>
                {componentToRender}
                {additionalBanner}
              </div>
            );
          })}
          <FeaturedJobs jobs={jobs} />
        </CenterWrapper>
      </div>
    </Frame>
  );
};
export default Section;
