import { FC } from "react";
import Card from "Components/Card";
import {
  Content,
  ActionWrapper,
  StyledLink,
} from "./Action.styled";
import Link from "next/link";
import Subscribe from "../Subscribe";
type Props = {
  type: string;
};

const Action: FC<Props> = ({ type }) => {
  if (type === "subscriptions") {
    return <Subscribe />;
  }
  return (
    <ActionWrapper>
      <Card tab={type === "jobs" ? "post a job" : "post event"}>
        <Content>
          {type === "jobs"
            ? "Post a job for free via our easy, user-friendly interface and get applicants within moments."
            : "Create and post your event online. It's fast, easy and free"}
        </Content>
        {type === "jobs" ? (
          <StyledLink
            href="https://recruiters.new-job-today.co.uk/"
            target="_blank"
          >
            Post job
          </StyledLink>
        ) : (
          <Link href="/whats-on/create">
            <StyledLink>
              Post event
            </StyledLink>
          </Link>
        )}
      </Card>
    </ActionWrapper>
  );
};

export default Action;
