import Title from "Components/Title";
import { useState, useEffect, FC } from "react";
import xml2js from "xml2js";
import { JobsWrapper } from "./FeaturedJobs.styled";
import Job from "./Job";

interface Props {
  jobs: {
    jobs: string;
  };
}

const FeaturedJobs: FC<Props> = ({ jobs }) => {
  const [result, setResult] = useState<any[]>();

  useEffect(() => {
    let parser = new xml2js.Parser();
    parser.parseString(jobs?.jobs, function (err: string, result: any) {
      if (result) {
        setResult(result.source && result.source.job && result.source.job);
      }
    });
  }, [jobs]);
  if (!result) {
    return null;
  }

  return (
    <>
      <Title type="jobs">Career news and advice</Title>

      <JobsWrapper>
        {result.map(
          (
            item: {
              url: string[];
              LogoUrl: string[];
              title: string[];
              city: string[];
            },
            i: number
          ) => {
            return <Job key={i} job={item} />;
          }
        )}
      </JobsWrapper>
    </>
  );
};

export default FeaturedJobs;
