import styled from "styled-components";

export const Wrapper = styled.div``;

export const InnerWrapper = styled.div`
    max-width: 975px;
    margin: 1rem auto;
    @media (max-width: 1150px) {
        max-width: 95vw;
        margin: 1rem auto;
    }
    &.section-wrapper {
      margin: 250px auto 1rem;
        @media (max-width: 1150px) {
          margin: 1rem auto;
        }
    }
`;
