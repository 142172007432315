import { FC, useContext } from "react";
import {
  SubscribeItemWrapper,
  StyledLink,
  ForwardArrow,
  RightSideImage,
} from "./SubscribeItem.styled";
import { IoNewspaperOutline } from "react-icons/io5";
import Link from "next/link";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { PublicationContext } from "common/context/Publication";
import { useRouter } from "next/router";

type Props = {
  tab: string;
  content: string;
  icon: string;
};

const SubscribeItem: FC<Props> = ({ tab, content, icon }) => {
  const { push } = useRouter();
  const { publication } = useContext<publicationTypes>(PublicationContext);
  return (
    <SubscribeItemWrapper>
      <div>
      <div className="subscribe_left">
        <span className="tab">{tab}</span>
        <div className="content">
          <span style={{ marginBottom: "20px"}}>{content}</span>
          <div style={{ marginTop: "auto"}}
            onClick={() =>
              push(
                tab === "Digital editions"
                  ? "/subscription/digital_subscriptions"
                  : tab === "Digital advertising"
                    ? "https://iserve.tindlenews.co.uk/"
                    : publication === "iomtoday.co.im"
                      ? "/subscription/print_subscription"
                      : "/print-subscription"
              )
            }
          >
            <StyledLink>
              Sign up <ForwardArrow/>
            </StyledLink>
          </div>
        </div>
      </div>
      </div>
      <RightSideImage type={icon} />
    </SubscribeItemWrapper>
  );
};
export default SubscribeItem;
