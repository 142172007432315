import React, { useEffect, FC } from "react";
import { useAdsContext } from "common/context/Ads/ads";
import { dfp } from "common/ads/dfp";

interface Props {
  id: string;
  className?: string;
  width: string;
  height: string;
}

const Ad: FC<Props> = ({ id, className, width = 350, height = 250 }) => {
  const { isLoading } = useAdsContext();
  useEffect(() => {
    if (!isLoading && !!id) {
      dfp.showSlot(id);
    }
  }, [isLoading, id]);

  return (
    <div
      id={id}
      className={className}
      style={{
        width,
        height,
      }}
    />
  );
};

export default Ad;
