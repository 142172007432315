import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { useContext } from "react";
import { SubscribeWrapper } from "./Subscribe.styled";
import SubscribeItem from "./SubscribeItem";

const Subscribe = () => {
  const { config } = useContext<publicationTypes>(PublicationContext);
  const pubConfig: { [unit: string]: string } = {
    ["petersfieldpost.co.uk"]: "#B11319",
    ["farnhamherald.com"]: "#00589F",
    ["haslemereherald.com"]: "#00589F",
    ["liphookherald.com"]: "#00589F",
    ["bordonherald.com"]: "#00589F",
    ["altonherald.com"]: "#00589F",
    ["abergavennychronicle.com"]: "#00589F",
    ["monmouthshirebeacon.co.uk"]: "#00589F",
    ["chepstowbeacon.co.uk"]: "#00589F",
    ["brecon-radnor.co.uk"]: "#00589F",
    ["cambrian-news.co.uk"]: "#B11319",
    ["tenby-today.co.uk"]: "#228747",
    ["narberth-and-whitland-today.co.uk"]: "#228747",
    ["pembroke-today.co.uk"]: "#228747",
    ["theforester.co.uk"]: "#228747",
    ["rossgazette.com"]: "#00589F",
    ["theforestreview.co.uk"]: "#228747",
  };

  const renderImage = (color: string, type: string) => {
    if (color === "#228747") {
      if (type === "digital") {
        return "icons/green_web.png";
      } else {
        return "icons/green_paper.png";
      }
    }
    if (color === "#B11319") {
      if (type === "digital") {
        return "icons/peters_digital.png";
      } else {
        return "icons/peters_paper.png";
      }
    }
    if (color === "#00589F") {
      if (type === "digital") {
        return "icons/digital.png";
      } else {
        return "icons/paper.png";
      }
    }
    return "icons/paper.png";
  };

  const digitalImage = renderImage(pubConfig[config.publication], "digital");
  // const paperImage = renderImage(pubConfig[config.publication], "paper");
  const { publication } = useContext<publicationTypes>(PublicationContext);

  const paperImage: { [unit: string]: string } = {
    ["haslemereherald.com"]: "/icons/sub_Icons/Haslemere.png",
    ["abergavennychronicle.com"]: "/icons/sub_Icons/Abergavenny.png",

    ["petersfieldpost.co.uk"]: "/icons/sub_Icons/Petersfield.png",

    ["farnhamherald.com"]: "/icons/sub_Icons/Farnham.png",

    ["altonherald.com"]: "/icons/sub_Icons/Alton.png",

    ["bordonherald.com"]: "/icons/sub_Icons/Bordon.png",

    ["liphookherald.com"]: "/icons/sub_Icons/liphook.png",

    ["monmouthshirebeacon.co.uk"]: "/icons/sub_Icons/Monmouth.png",

    ["chepstowbeacon.co.uk"]: "/icons/sub_Icons/Chepstow.png",

    ["brecon-radnor.co.uk"]: "/icons/sub_Icons/Brecon.png",

    ["cambrian-news.co.uk"]: "/icons/sub_Icons/Cambrian.png",

    ["tenby-today.co.uk"]: "/icons/sub_Icons/Tenby.png",

    ["narberth-and-whitland-today.co.uk"]: "/icons/sub_Icons/Narberth.png",

    ["pembroke-today.co.uk"]: "/icons/sub_Icons/Pembroke.png",

    ["theforester.co.uk"]: "/icons/sub_Icons/TheForester.png",

    ["rossgazette.com"]: "/icons/sub_Icons/Ross.png",

    ["theforestreview.co.uk"]: "/icons/sub_Icons/ForestReview.png",

    ["tavistock-today.co.uk"]: "/icons/sub_Icons/Tavistock.png",

    ["middevonadvertiser.co.uk"]: "/icons/sub_Icons/MidDevonAdvertiser.png",

    ["dawlish-today.co.uk"]: "/icons/sub_Icons/Dawlish.png",

    ["teignmouth-today.co.uk"]: "/icons/sub_Icons/Teignmouth.png",

    ["dartmouth-today.co.uk"]: "/icons/sub_Icons/Dartmouth.png",

    ["ivybridge-today.co.uk"]: "/icons/sub_Icons/Ivybridge.png",

    ["kingsbridge-today.co.uk"]: "/icons/sub_Icons/Kingsbridge.png",

    ["southhams-today.co.uk"]: "/icons/sub_Icons/Kingsbridge.png",

    ["totnes-today.co.uk"]: "/icons/sub_Icons/Totnes.png",

    ["tamarvalleytimes.co.uk"]: "/icons/sub_Icons/TamarValley.png",

    ["okehampton-today.co.uk"]: "/icons/sub_Icons/Okehampton.png",

    ["creditoncourier.co.uk"]: "/icons/sub_Icons/Crediton.png",

    ["iomtoday.co.im"]: "/icons/sub_Icons/IsleofMan.png",

    ["mnrjournal.co.uk"]: "/icons/sub_Icons/MidsomerNortonChewValley.png",

    ["wsfp.co.uk"]: "/icons/sub_Icons/WestSomerset.png",

    ["wellington-today.co.uk"]: "/icons/sub_Icons/Wellington.png",

    ["holsworthy-today.co.uk"]: "/icons/sub_Icons/Holsworthy.png",

    ["cornish-times.co.uk"]: "/icons/sub_Icons/CornishTimes.png",

    ["thepost.uk.com"]: "/icons/sub_Icons/CornishDevonPost.png",
    ["bude-today.co.uk"]: "/icons/sub_Icons/BudeandStratton.png",

    ["voicenewspapers.co.uk"]: "/icons/sub_Icons/voice.jpg",
    ["wokingnewsandmail.co.uk"]: "/icons/sub_Icons/woking.jpg",
  };

  return (
    <SubscribeWrapper>
      <SubscribeItem
        icon={paperImage[publication]}
        tab="Digital editions"
        content="Click on the link below to access online editions."
      />
      <SubscribeItem
        icon="/icons/digital_icon.jpg"
        tab="Digital advertising"
        content="Promote your business to local customers by using iServe, our self-serve digital advertising tool."
      />
    </SubscribeWrapper>
  );
};

export default Subscribe;
