import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const JobsWrapper = styled.div`
  display: grid;
  margin: 20px 0;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
  }
`;