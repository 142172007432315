import React, { useEffect, FC, useContext } from "react";
import { useAdsContext } from "common/context/Ads/ads";
import { dfp } from "common/ads/dfp";
import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";

interface Props {
  id: string;
  width?: string;
  height?: string;
}

const Skin: FC<Props> = ({ id }) => {

  const { isLoading } = useAdsContext();

  useEffect(() => {
    if (!isLoading && !!id) {
      dfp.showSlot(id);
    }
  }, [isLoading, id]);

  return <div id={id} />;
};

export default Skin;
