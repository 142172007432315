import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const JobTitle = styled.h1`
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
  color: ${({ theme }) => theme.default.color.primary};
  text-transform: uppercase;
`;

export const JobLogo = styled.img`
  height: 44px;
  width: 88px;
  object-fit: cover;
`;
export const JobLocation = styled.span`
  font-family:  ${({ theme }) => theme.default.fontFamily.secondaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.default.color.text.medium};
`;

export const JobWrapper = styled.a`
  display: grid;
  grid-template-columns: 88px 2fr;
  padding: 0 16px;
  column-gap: 16px;
  border-left: ${({ theme }) => `1px solid ${theme.default.color.text.light} `};
  border-right: ${({ theme }) =>`1px solid ${theme.default.color.text.light}`};
`;
